import { useSelector } from 'react-redux';
import { RootState } from '../../../../store/store';
import styles from './ProductsNavigation.module.scss';
import { useEffect, useState } from 'react';
import { getProductById } from '../../../../services/products.service';
import { Product } from '../../models';
import { Category } from '../products-categories/models/Category.model';
import { useNavigate } from 'react-router-dom';

export default function ProductsNavigation() {
    const filters = useSelector((state: RootState) => state.filters);
    const navigate = useNavigate();
    const [categoryName, setCategoryName] = useState<string>("");
    useEffect(() => {
        const categoryId = filters.categoryId;
        const productName = filters.productName;
        if (categoryId !== null && categoryId !== 'all') {
            getProductById(categoryId).then((response: any) => {
                if (response.data) {
                    const category = response.data as Category;
                    if (category.name) {
                        setCategoryName(category.name);
                    }
                }
            })
        }
        else if (categoryId === 'all' && productName === null) {
            setCategoryName('Wszystkie produkty');
        }
        else {
            setCategoryName('Wyniki wyszukiwania');
        }
    }, [filters])
    return (
        <div className={styles.navigation}>
            {categoryName !== "" &&
                <div onClick={() => { navigate("/"); navigate(0) }} className={`${styles.text} ${styles.navigation_wrapper}`}>
                    Start /&nbsp;
                    <div className={`${styles.text} ${styles.green_text}`}>
                        {categoryName}
                    </div>
                </div>
            }
        </div >
    )
}
