import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { PickupPoint } from '../map/models';
import styles from "./SelectList.module.scss";
import { Typography } from '@mui/material';
import './styles.scss';
import { useTranslation } from 'react-i18next';

export default function SelectList(props: any) {
    const { disabled = false, pickupPoints = [], showDefaultOption = false, handleSelectChange = () => { } } = props;
    const [selectedValue, setSelectedValue] = useState('');
    const DEFAULT_OPTION_VALUE = "any";
    const { t, i18n } = useTranslation('translation');

    useEffect(() => {
        setSelectedValue(props.selectedPickupPointId);
    }, [props.selectedPickupPointId]);

    const handleChange = (event: SelectChangeEvent<string>) => {
        setSelectedValue(event.target.value);
        handleSelectChange(event);
    };

    return (
        <div>
            <Box className={styles.container}>
                <FormControl disabled={disabled}>
                    <Select
                        value={selectedValue || DEFAULT_OPTION_VALUE}
                        onChange={handleChange}
                        className={styles.select}
                        renderValue={(value) =>
                            value === DEFAULT_OPTION_VALUE
                                ? t("pickupPointsFilters.any")
                                : pickupPoints.find((pickupPoint: PickupPoint) => pickupPoint.id == value)?.name
                        }
                    >
                        {showDefaultOption && (
                            <MenuItem key="default" value={DEFAULT_OPTION_VALUE} className={styles.menuItem}>
                                <Typography variant="subtitle1">{t('pickupPointsFilters.any')}</Typography>
                            </MenuItem>
                        )}
                        {Array.isArray(pickupPoints) && pickupPoints.map((pickupPoint: PickupPoint) => (
                            <MenuItem key={pickupPoint?.id} value={pickupPoint?.id} className={styles.menuItem}>
                                <div>
                                    <Typography variant="subtitle1">{pickupPoint?.name}</Typography>
                                    <Typography variant="body2" color="textSecondary">
                                        {pickupPoint?.address?.streetName + " " + pickupPoint?.address?.buildingNumber}
                                    </Typography>
                                    <Typography variant="body2" color="textSecondary">
                                        {pickupPoint?.address?.postalCode + " " + pickupPoint?.address?.city}
                                    </Typography>
                                </div>
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </Box>
        </div >
    );
}
