import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { PickupPoint } from '../../../shared/map/models';
import { getPickupPoints } from '../../../shared/map/services';
import styles from './PickupPointsFilters.module.scss';
import { RootState } from '../../../store/store';
import { useDispatch, useSelector } from 'react-redux';
import { setFilters } from '../../../store/actions';
import { Filters } from '../../../models/filters.model';
import SelectList from '../../../shared/select-list/SelectList';

export default function PickupPointsFilters() {
    const productFilters = useSelector((state: RootState) => state.filters);
    const dispatch = useDispatch();

    const { t, i18n } = useTranslation('translation');
    const [pickupPoints, setPickupPoints] = useState<PickupPoint[]>([]);
    const [selectedOption, setSelectedOption] = useState('any');
    const [selectedPickupPointId, setSelectedPickupPointId] = useState<string | null>('');
    const DEFAULT_OPTION_VALUE = "any";

    useEffect(() => {
        getPickupPoints().then((response) => {
            if (response) {
                setPickupPoints(response.data);
                initFilters(response.data as PickupPoint[]);
            }
        });
    }, []);


    const initFilters = (pickupPoints: PickupPoint[]) => {
        const storedPickupPointFilter = localStorage.getItem('pickupPointsFilter');
        const isValidPickupPoint = pickupPoints.some((pickupPoint: PickupPoint) => pickupPoint.id === storedPickupPointFilter);
        const pickupPointFilter = isValidPickupPoint ? storedPickupPointFilter : DEFAULT_OPTION_VALUE;

        if (!isValidPickupPoint) {
            localStorage.removeItem('pickupPointsFilter');
            setSelectedOption('default');
        } else {
            setSelectedOption('selected');
        }

        setSelectedPickupPointId(pickupPointFilter);

        const filters: Filters = {
            pickupPointId: pickupPointFilter,
            categoryId: productFilters.categoryId,
            productName: productFilters.productName,
            sortBy: productFilters.sortBy,
        };
        dispatch(setFilters(filters));
    };


    const handleSelectChange = (event: any) => {
        const id = event.target.value;
        if (id !== DEFAULT_OPTION_VALUE) {
            setSelectedPickupPointId(id);
            localStorage.setItem('pickupPointsFilter', id as string);
            const filters: Filters = {
                pickupPointId: id,
                categoryId: productFilters.categoryId,
                productName: productFilters.productName,
                sortBy: productFilters.sortBy
            };
            dispatch(setFilters(filters));
            return;
        }
        setSelectedPickupPointId('');
        localStorage.setItem('pickupPointsFilter', '' as string);
        const filters: Filters = {
            pickupPointId: null,
            categoryId: productFilters.categoryId,
            productName: productFilters.productName,
            sortBy: productFilters.sortBy
        }
        dispatch(setFilters(filters));
    };

    return (
        <div className={styles.filtersContainer}>
            <p className={`${styles.heading} ${styles.custom_underline}`}>{t("pickupPointsFilters.heading")}</p>
            <div className={styles.secondRow}>
                <div className={styles.select_container}>
                    <SelectList displayDefaultValue={true} selectedPickupPointId={selectedPickupPointId} pickupPoints={pickupPoints} showDefaultOption={true} handleSelectChange={handleSelectChange} />
                </div>
            </div>
        </div>
    );
}
